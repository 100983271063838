<template>

<app-content :loading="is.loading">

	<app-content-head title="Settings" subtitle="Manage your library settings.">

		<app-content-head-actions-item :disabled="!isDirty" icon="undo" text="Undo" v-on:click="onUndoClick" />
		<app-content-head-actions-item :loading="is.saving" :disabled="!isDirty || !isValid" icon="save" text="Save" v-on:click="onSaveClick" />

	</app-content-head>

	<app-content-body>

		<app-content-box>

			<app-input-text label="BGG Username" placeholder="Enter username..." v-model="model.library.username" :validation="$v.model.library.username" />

		</app-content-box>

	</app-content-body>

</app-content>

</template>

<script>

import mixForm from '@/mixin/form'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			settings: true,
			forceModelLoad: true,
			model: {
				library: {
					username: ''
				}
			}
		}

	},

	validations: {
		model: {
			library: {}
		}
	},

	computed: {

		defaultSettings: function() {

			return []

		}

	}

}

</script>

<style scoped>

</style>